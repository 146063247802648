import * as React from 'react'
import { Link } from 'gatsby'
import { PageContextPropsType } from '../../i18n/i18n'
import LayoutSimpleFooter from '../components/LayoutSimpleFooter'
import NoIndex from '../components/NoIndex'
import classNames from 'classnames/bind'
import * as SiteWideStyles from '../styles/SiteWide.css'
import * as NotFoundStyles from '../styles/NotFound.css'

const cx = classNames.bind(NotFoundStyles)

const NotFoundPage = ({ pageContext }: PageContextPropsType) => {
  return (
    <LayoutSimpleFooter pageContext={pageContext}>
      <NoIndex title="KARTE Blocks | NOT FOUND" description="お探しのページが見つかりません。" path="/404/" />

      <main className={cx('Container')}>
        <div className={SiteWideStyles.Grid}>
          <h1 className={cx('Headline')}>404</h1>
          <h2 className={cx('SubHeadline')}>NOT FOUND</h2>
          <p className={cx('Message')}>
            お探しのページが見つかりません。
            <br />
            一時的にアクセスできない状態か、
            <br className={SiteWideStyles.DisplayMobileOnly} />
            移動または削除された可能性があります。
          </p>

          <Link to="/" className={cx(SiteWideStyles.Button, SiteWideStyles.LargeButton)}>
            ホームへ戻る
          </Link>
        </div>
      </main>
    </LayoutSimpleFooter>
  )
}

export default NotFoundPage
